<template>
	<div>
		<div class="page-sub-box clearfix ui-glid-box top-box">
			<div class="fl per100">
				<table class="table_form line-bin th-bold">
					<colgroup>
						<col style="width: 130px" />
						<col style="width: auto" />
					</colgroup>
					<tbody>
						<tr>
							<th>컬럼 *</th>
							<td class="clearfix">
								<DxTextBox v-model="form.dataField" width="100%" :height="30" :disabled="true" />
							</td>
						</tr>
						<tr>
							<th>항목명 *</th>
							<td class="clearfix">
								<DxTextBox v-model="form.caption" width="100%" :height="30">
									<DxValidator validation-group="validationGroupName2">
										<DxRequiredRule message="필수 값 입니다." />
									</DxValidator>
								</DxTextBox>
							</td>
						</tr>
						<tr>
							<th>출력형식 *</th>
							<td class="clearfix">
								<DxSelectBox
									placeholder="선택"
									:items="tools.format"
									v-model="form.format"
									value-expr="id"
									display-expr="text"
									width="100%"
									:height="30"
									styling-mode="outlined"
								>
									<DxValidator validation-group="validationGroupName2">
										<DxRequiredRule message="필수 값 입니다." />
									</DxValidator>
								</DxSelectBox>
							</td>
						</tr>
						<tr>
							<th>넓이 *</th>
							<td class="clearfix">
								<DxTextBox v-model="form.width" width="100%" :height="30" :onKeyDown="keydownOnlyNumber">
									<DxValidator validation-group="validationGroupName2">
										<DxRequiredRule message="필수 값 입니다." />
									</DxValidator>
								</DxTextBox>
							</td>
						</tr>
						<tr>
							<th>정렬 *</th>
							<td class="clearfix">
								<DxSelectBox
									placeholder="선택"
									:items="tools.align"
									v-model="form.align"
									value-expr="id"
									display-expr="text"
									width="100%"
									:height="30"
									styling-mode="outlined"
								>
									<DxValidator validation-group="validationGroupName2">
										<DxRequiredRule message="필수 값 입니다." />
									</DxValidator>
								</DxSelectBox>
							</td>
						</tr>
						<tr>
							<th>항목설명 *</th>
							<td class="clearfix">
								<DxTextBox v-model="form.description" :width="200" :height="30">
									<DxValidator validation-group="validationGroupName2">
										<DxRequiredRule message="필수 값 입니다." />
									</DxValidator>
								</DxTextBox>
							</td>
						</tr>
						<tr>
							<th>멀티헤더명</th>
							<td class="clearfix">
								<DxTextBox v-model="form.multiHeaderNm" :width="200" :height="30"> </DxTextBox>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</template>

<script>
import { DxSelectBox } from 'devextreme-vue/select-box';
import { DxTextBox } from 'devextreme-vue/text-box';
import { DxValidator, DxRequiredRule } from 'devextreme-vue/validator';

export default {
	components: {
		DxSelectBox,
		DxTextBox,
		DxValidator,
		DxRequiredRule,
	},
	props: {
		column: {
			type: Object,
			default: () => ({}),
		},
		tools: {
			type: Object,
			default: () => ({}),
		},
	},
	watch: {},
	data() {
		return {
			form: {
				dataField: '',
				caption: '',
				width: '',
				format: '',
				description: '',
				align: '',
				visible: '',
				multiHeaderNm: '',
			},
		};
	},
	computed: {},
	methods: {
		keydownOnlyNumber({ event }) {
			// 백스페이스, 탭, ESC, Enter & 숫자만 가능
			if (![8, 9, 13, 27].includes(event.keyCode) && !/^\d*$/.test(event.key)) event.preventDefault();
		},
		initColumnForm(column) {
			Object.keys(this.form).forEach(key => {
				if (column[key] !== undefined) this.form[key] = column[key];
			});
		},
		getForm() {
			return this.form;
		},
	},
	created() {},
	mounted() {
		console.log('column', this.column);
	},
};
</script>

<style scoped>
.sub_new_style01 .page_search_box .inner div {
	display: inline-block;
}

.sub_new_style01 .page_search_box .inner > div {
	vertical-align: middle;
	margin-right: 10px;
}

.th-bold > tbody > tr > th {
	font-weight: 400;
}
</style>
